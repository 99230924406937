<template>
  <section
    class="notifications"
    :class="{ mobile: props.isMobile }"
    data-t="notifications"
  >
    <header v-if="!props.isMobile" class="notifications-header">
      <div class="title">
        <h2>{{ t('notifications.title') }}</h2>
        <StCounter
          v-if="activeNotificationsCounter"
          :label="activeNotificationsCounter"
          size="xs"
          bg-color="orange"
        />
      </div>
      <button
        v-if="items.length && activeNotificationsCounter"
        class="read-all"
        @click="handleReadAll"
      >
        {{ t('notifications.readAll') }}
      </button>
    </header>
    <StDrawerHeader
      v-else
      class="scroll-header"
      :class="{ 'arrived-top': arrivedState.top }"
      data-t="drawer-header"
    >
      <h2 class="header-title">{{ t('notifications.title') }}</h2>
      <button v-if="items.length" class="read-all" @click="handleReadAll">
        {{ t('notifications.readAll') }}
      </button>
    </StDrawerHeader>
    <div ref="el" class="scroll-container">
      <div v-if="isLoading || items.length">
        <TransitionGroup
          v-if="items.length"
          name="list"
          tag="ul"
          class="notifications-list"
          data-t="notifications-list"
        >
          <li v-for="item in notifications" :key="item.id">
            <NotificationItem :item="item" :is-mobile="props.isMobile" />
          </li>
          <li ref="endOfTheList" class="loader">
            <StSpinner v-if="isLoading" :size="20" class="loader" />
          </li>
        </TransitionGroup>
        <div v-if="isLoading" class="notifications-list">
          <StSkeletonLoader
            v-for="i in 10"
            :key="i"
            height="60px"
            bg-color="var(--background-secondary)"
            :shimmer-opacity="0.28"
          />
        </div>
      </div>
      <div v-else class="empty-list" data-t="empty">
        <p>{{ t('notifications.empty') }}</p>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import { useInfiniteScroll } from '@st/use/composables'
import { useInfiniteList } from '@st/api/composables/useInfiniteList'
import StDrawerHeader from '@st/ui/components/StDrawer/StDrawerHeader.vue'
import NotificationItem from './parts/NotificationItem.vue'
import { useNotificationsStore } from '../../stores/useNotificationsStore'
import type { Notification } from '../../types'

const props = defineProps<{
  isMobile?: boolean
}>()

const { t } = useI18n()

const { items, canLoadMore, loadMore, isLoading } = useInfiniteList({
  url: '/user-notification/find',
  orderBy: [{ fieldName: 'createdAt', sortOrder: 'DESC' }],
  perPage: 10,
})

const notifications = computed(() => items.value as unknown as Notification[])

const unReadedItems = computed(() =>
  notifications.value.filter(({ read }) => !read),
)

const endOfTheList = ref<HTMLDivElement>()
useInfiniteScroll({
  el: endOfTheList,
  loadMore: async () => {
    if (canLoadMore.value) {
      await loadMore()
    }
  },
  disabledObserver: computed(() => !canLoadMore.value),
  options: { rootMargin: '800px', threshold: 0 },
})

const notificationsStore = useNotificationsStore()
const { readAllNotifications } = notificationsStore
const { activeNotificationsCounter } = storeToRefs(notificationsStore)
async function handleReadAll() {
  await readAllNotifications()
  unReadedItems.value.forEach((item) => {
    /* to avoid requests */
    // eslint-disable-next-line no-param-reassign
    item.read = true
  })
}

const el = ref<HTMLDivElement>()
const { arrivedState } = useScroll(el)
</script>

<style scoped>
.scroll-container {
  overflow: hidden;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  min-height: 0;
  max-height: 560px;
  margin-right: calc(var(--spacing-200) * -1);
  padding-right: var(--spacing-200);
}

.notifications-list {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-050);
  align-items: center;

  margin: 0;
  padding: var(--spacing-050);

  list-style: none;

  border-radius: var(--border-radius-0, 0);

  li {
    display: flex;
    width: 100%;
  }
}

.notifications-header {
  display: flex;
  gap: var(--spacing-150);
  align-items: center;
  padding: var(--spacing-125) var(--spacing-200);
}

.title {
  display: flex;
  gap: var(--spacing-075);
  align-items: center;

  h2 {
    margin: 0;
    font: var(--desktop-text-md-medium);
    color: var(--text-primary);
  }
}

.read-all {
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;

  margin-left: auto;

  font: var(--desktop-text-sm-semibold);
  color: var(--text-tertiary);

  background-color: transparent;
  border: none;

  &:hover {
    color: var(--text-primary);
  }
}

.loader {
  display: flex;
  align-items: center;
  justify-content: center;
}

.empty-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;

  width: 178px;
  height: 132px;
  margin: auto;
  /* stylelint-disable */
  margin-top: 88px;
  margin-bottom: 100px;
  /* stylelint-enable */

  background-image: url('./empty.png');
  background-repeat: no-repeat;
  background-position: top center;
  background-size: contain;

  p {
    margin: 0;
    font: var(--desktop-text-xs-medium);
    color: var(--text-tertiary);
  }
}

.notifications {
  overflow: hidden;
  display: flex;
  flex-direction: column;

  width: 360px;

  background: var(--background-secondary);
  border-radius: var(--border-radius-100);

  &.mobile {
    width: 100%;
    height: 100%;
    background: var(--background-base);

    .read-all {
      margin-left: var(--spacing-150);
    }

    .scroll-container {
      position: relative;
      max-height: 1000px;
    }

    .scroll-header {
      position: relative;
      border-bottom: var(--border-width-light) solid transparent;

      &::before {
        pointer-events: none;
        content: '';

        position: absolute;
        z-index: 1;
        right: 0;
        bottom: -32.5px;
        left: 0;

        height: 32px;

        opacity: 0;
        background: linear-gradient(#111118 0%, rgb(17 17 24 / 0%) 100%);
      }

      &:not(.arrived-top) {
        border-bottom: var(--border-width-light) solid var(--border-primary);

        &::before {
          opacity: 1;
        }
      }
    }

    .notifications-list {
      padding: var(--spacing-100) var(--spacing-200) var(--spacing-200)
        var(--spacing-200);
    }

    .empty-list {
      width: 200px;
      height: 149px;
      margin: auto;

      p {
        font: var(--mobile-text-content-regular);
        color: var(--text-secondary);
      }
    }
  }
}

.list-enter-active,
.list-leave-active {
  transition: translate 0.25s ease-in;
}

.list-enter-from,
.list-leave-to {
  transform: translateY(10px);
  opacity: 0;
}

.header-title {
  margin: 0;
  font: var(--mobile-title-2-semibold);
}
</style>
